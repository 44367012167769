<template>
  <SmartMorphIcon
    :includeFirstStep='true'
    :state.sync="localState"
    @iconClick="s => $emit('iconClick', s)"
    @stateChange="s => $emit('stateChange', s)"
    @updateState="s => $emit('updateState', s)"
    frameIdPrefix="step-" :rootClass="rootClass">
    <g id="step-1">
      <g>
      <path id="line1" stroke="black" stroke-width="20" d="M20 75L149.904 225L279.808 75" />
      </g>
    </g>
    <g id="step-2">
      <g>
      <path id="line1" stroke="black" stroke-width="20" d="M20 74.9995L149.904 75L279.808 74.9995" />
      </g>
    </g>
    <g id="step-3">
      <g>
      <path id="line1" stroke="black" stroke-width="20" d="M23 213L149.904 75L270 213" />
      </g>
    </g>
  </SmartMorphIcon>
</template>
<script>
import { SmartMorphAnimationState } from '@/core/view/smartMorph';
import SmartMorphIcon from '@/components/InteractiveIcons/SmartMorphIcon.vue';

export default {
  name: 'DropdownIcon',
  components: { SmartMorphIcon },
  props: {
    rootClass: {
      type: String,
      default: '',
    },
    state: {
      type: Object,
      required: false,
    },
    initAtEnd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localState: (this.initAtEnd) ? SmartMorphAnimationState.End : this.state,
    };
  },
  watch: {
    state(newVal) {
      if (this.localState !== newVal) {
        this.localState = newVal;
      }
    },
    localState(newVal) {
      this.$emit('update:state', newVal);
    },
  },
};
</script>
