<template>
  <ul class="master-sub-menu" :class="isRzMenu ? 'rz-menu': ''">
    <li v-for="(link, i) in linkslist.links" :key="i" :class="link.element_id">
        <span @click="$emit('animState')">
          <rz-link-item class="link-box" :link="link">
            <span class="link-text">{{link.name}}</span>
            <div class="desc-area" v-if="link.desc">
              <rz-markdown :text="link.desc"></rz-markdown>
            </div>
          </rz-link-item>
        </span>
    </li>
  </ul>
</template>
<style lang="scss">
  .master-sub-menu {
    margin: size(8) size(30) size(16) size(30);
    border-left: 1px solid var(--divider);
    font-size: size(14);
    &.rz-menu {
      border-left: none;
    }
    li {
      display: block;
      a {
        display: block;
        padding: size(12);
        font-size: size(12);
        color: var(--regular-text-inv);
        text-transform: uppercase;
        &.link-box {
          text-transform: initial;
        }
        &:hover {
          @include jump-up-animation;
        }
      }
    }

    @media screen and (min-width: $breakpoint-lg) {
      margin: size(8) size(8) size(8) size(20);
      border-left: none;
      li {
        border-bottom: 1px solid var(--divider);

        &:last-child {
          border-bottom: none;
        }

        a {
          padding: size(12) 0;
        }
      }
    }
  }

  .docs-wrap {
    .master-sub-menu {
      li {
        .desc-area {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    .master-menu {
      &.overlay-menu {
        .with-sub-menu {
          &:hover {
            .sub-menu-items {
              background: var(--master-bg-lite);
              .master-sub-menu {
                li {
                  border-color: var(--secondary-text);
                  a {
                    color: var(--secondary-text);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
<script>

export default {
  props: {
    linkslist: Object,
    isRzMenu: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
