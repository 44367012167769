<template>
  <a
    :href="consoleLink"
    title="Console" class="btn btn--outline" rel="noopener">
    <!-- multihost user issue -->
    <!-- <span v-if="bootData.user && bootData.user.user">Hey {{bootData.user.name}}</span>
    <span v-else-if="activeUser && activeUser.loggedIn">Console</span>
    <span v-else>Sign in</span> -->
    Console
  </a>
</template>
<style lang="scss" scoped>
</style>
<script>
import { mapState } from 'vuex';
import Config from '@/config';

export default {
  name: 'ErrorState',
  computed: {
    ...mapState({
      bootData: (state) => state.boot.instance,
      activeUser: (state) => state.user.activeUser,
    }),
  },
  props: {
    isPaygo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      consoleLink: Config.consoleHost,
    };
  },
};
</script>
