<template>
  <div class="svg-icon" :class="rootClass">
    <svg  @click="onClick" ref="icon" width="300" height="300" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
      <slot ></slot>
    </svg>
    <div v-if="debug">
      <input v-model="seekProgress" type="range" step="0.001" min="0" max="100" /> {{seekProgress}}<br/>
      <input v-model="frame" type="number" /> {{frame}}<br/>
    </div>
  </div>
</template>
<script>
import { SmartMorph } from '@/core/view/smartMorph';

export default {
  name: 'SmartMorphIcon',

  props: {
    frameIdPrefix: String,
    rootClass: {
      type: String,
      default: '',
    },
    state: {
      type: Object,
      required: false,
    },
    includeFirstStep: {
      type: Boolean,
      default: false,
    },
    debug: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      morph: null,
      seekProgress: 0,
      frame: 0,
    };
  },

  watch: {
    state(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.changeState(newVal);
      }
    },
    seekProgress(newVal) {
      this.morph.seek(newVal);
    },
    frame(newVal) {
      this.morph.gotoFrame(parseInt(newVal, 10));
    },
  },

  mounted() {
    this.morph = new SmartMorph({
      rootElement: this.$refs.icon,
      frameIdPrefix: this.frameIdPrefix,
      includeFirstStep: this.includeFirstStep,
      state: this.state,
      onStateChange: (state) => this.syncState(state),
    });
    this.morph.setup();
    this.morph.createAnimationData();
    this.morph.createAnimationTimeline();
    this.syncState();
  },

  methods: {
    syncState() {
      this.$emit('update:state', this.morph.animationState);
      this.$emit('updateState', this.morph.animationState);
    },

    updateEvent(event) {
      setTimeout(() => {
        const state = this.morph.animationState;
        if (event) {
          this.$emit(event, state);
        }
        this.$emit('stateChange', state);
        this.syncState();
      }, 2);
    },
    onClick() {
      this.morph.toggleAnimation();
      this.updateEvent('iconClick');
    },
    changeState(newState) {
      this.morph.changeState(newState);
      this.updateEvent(null);
    },
  },
};
</script>
